import Vue from 'vue'
import App from './App.vue'


// import '@/style/reset.css' // 重置样式
import './assets/icon/iconfont.js' //引入阿里巴巴图标库js
import './assets/icon/iconfont.css'//引入阿里巴巴图标库css

import Vant from 'vant' // 引入vant-ui
import 'vant/lib/index.css' // 引入vant自带样式

import router from '@/router/index.js' // 引入vue-router


Vue.config.productionTip = false

Vue.use(Vant)

// 页面标题（当路由发生变化是更换标题）
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')