import Vue from 'vue'
import VueRouter from 'vue-router'

// 路由懒加载
const rolldetail = () => import('@/views/home/rolldetail')
const index = () => import('@/views/home/index')

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    alias: '/index',
    meta: {  //当路由发生改变是可以通过该值的title去更换页面标题
      title: '首页'
    },
    component: index
  },
  {
    path: '/rolldetail',
    name: 'rolldetail',
    alias: '/rolldetail',
    meta: {
      title: '产品详情'
    },
    component: rolldetail
  },
]

let router = new VueRouter({
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})
// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error => error)
// }

export default router